
<template>
    <b-container fluid>
        <iq-card>
            <template v-slot:headerTitle>
                <h4 class="card-title">{{ $t('grantDistribution.fund_distribution') }}</h4>
            </template>
             <template v-slot:body>
              <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
                <b-form  @submit.prevent="handleSubmit(register)" @reset.prevent="reset">
                  <b-row>
                    <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                        <b-form-group
                            class="row"
                            label-cols-sm="4"
                            :label="$t('grantDistribution.applicant_id')"
                            label-for="application_id"
                            >
                            <b-form-input
                                plain
                                v-model="search.application_id"
                                id="application_id"
                                >
                                <template v-slot:first>
                                    <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                            </b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                        <b-form-group
                            class="row"
                            label-cols-sm="5"
                            :label="$t('admission_form.fund_head')"
                            label-for="fund_sub_head_id"
                            >
                            <b-form-select
                                plain
                                v-model="search.fund_sub_head_id"
                                :options="fundSubHeadList"
                                id="fund_sub_head_id"
                                >
                                <template v-slot:first>
                                    <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                            </b-form-select>
                        </b-form-group>
                    </b-col>
                    <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                        <b-button type="button" variant="primary" @click="searchData">{{ $t('globalTrans.search') }}</b-button>
                    </b-col>
                  </b-row>
                </b-form>
              </ValidationObserver>
            </template>
        </iq-card>
        <b-row>
            <b-col md="12">
                <iq-card>
                    <template v-slot:headerTitle>
                        <h4 class="card-title">{{ $t('grantDistribution.applicant_list') }}</h4>
                    </template>
                    <template v-slot:body>
                        <b-overlay :show="loadingState">
                            <b-row>
                              <b-col md="12" class="table-responsive">
                                <b-table thead-class="table_head" striped :emptyText="$t('globalTrans.noDataFound')" show-empty bordered hover :items="fundList" :fields="columns" aria-hidden="loading | listReload ? 'true' : null">
                                  <template v-slot:cell(index)="data">
                                    {{ $n(data.index + pagination.slOffset) }}
                                  </template>
                                  <template v-slot:cell(application_id)="data">
                                    {{ $n(data.item.application_id, { useGrouping: false }) }}
                                  </template>
                                  <template v-slot:cell(status)="data">
                                    {{ getStatus(data.item.status) }}
                                  </template>
                                  <template v-slot:cell(action)="data">
                                    <!-- <b-button v-b-modal.modal-1 variant=" iq-bg-success mr-1 mb-1" size="sm" @click="details(data.item)"><i class="ri-eye-line m-0"></i></b-button> -->
                                    <!-- <b-button v-if="data.item.status !== 3" v-b-modal.modal-4 variant=" iq-bg-success mr-1 mb-1" size="sm" @click="edit(data.item)" title="Pay Now"><i class="ri-money-dollar-box-line"></i></b-button> -->
                                    <a href="javascript:" class="btn_table_action table_action_view" title="View Details" v-b-modal.modal-1 size="sm" @click="details(data.item)">
                                        <i class="fas fa-eye"></i>
                                    </a>
                                    <a href="javascript:" class="btn_table_action table_action_edit" title="Pay Now" v-if="data.item.status !== 3" v-b-modal.modal-4 size="sm" @click="edit(data.item)">
                                        <i class="fas fa-money-check"></i>
                                    </a>
                                  </template>
                                </b-table>
                                <b-pagination
                                  align="center"
                                  v-model="pagination.currentPage"
                                  :per-page="pagination.perPage"
                                  :total-rows="pagination.totalRows"
                                  @input="searchData"
                                />
                              </b-col>
                            </b-row>
                        </b-overlay>
                    </template>
                </iq-card>
            </b-col>
        </b-row>
        <b-modal id="modal-4" size="xl" :title="$t('grantDistribution.fund_distribution')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
          <Form :item="item" :key="editItemId"/>
        </b-modal>
        <b-modal id="modal-1" size="xl" :title="$t('grantDistribution.fund_distribution_info')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
          <Details :item="item" />
        </b-modal>
    </b-container>
</template>
<script>
import Form from './Form'
import Details from './Details'
import RestApi, { incentiveGrantServiceBaseUrl } from '@/config/api_config'
import { fundDistributionList } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'
import flatpickr from 'flatpickr'

export default {
  mixins: [ModalBaseMasterList],
  components: {
    Form,
    Details
  },
  data () {
    return {
      search: {
        application_id: '',
        fund_sub_head_id: 0
      },
      item: '',
      fundList: []
    }
  },
  mounted () {
    flatpickr('#datepicker', {})
  },
  computed: {
    fundSubHeadList () {
      return this.$store.state.incentiveGrant.commonObj.fundSubHeadList.map(item => {
        if (this.$i18n.locale === 'bn') {
          return { value: item.value, text: item.text_bn }
        } else {
          return { value: item.value, text: item.text_en }
        }
      })
    },
    columns () {
      const labels = [
        { label: this.$t('globalTrans.sl_no'), class: 'text-center' },
        { label: this.$t('globalTrans.application_id'), class: 'text-center' },
        { label: this.$t('globalTrans.name'), class: 'text-center' },
        { label: this.$t('admission_form.fund_head'), class: 'text-center' },
        { label: this.$t('globalTrans.amount'), class: 'text-center' },
        { label: this.$t('globalTrans.status'), class: 'text-center' },
        { label: this.$t('globalTrans.action'), class: 'text-center' }
      ]

      let keys = []

      if (this.$i18n.locale === 'bn') {
        keys = [
          { key: 'index' },
          { key: 'application_id' },
          { key: 'name_bn' },
          { key: 'fund_head_name_bn' },
          { key: 'requisition_amount' },
          { key: 'status' },
          { key: 'action' }
        ]
      } else {
        keys = [
          { key: 'index' },
          { key: 'application_id' },
          { key: 'name' },
          { key: 'fund_head_name' },
          { key: 'requisition_amount' },
          { key: 'status' },
          { key: 'action' }
        ]
      }

      return labels.map((item, index) => {
        return Object.assign(item, keys[index])
      })
    }
  },
  created () {
    this.loadData()
  },
  methods: {
    searchData () {
      this.loadData()
    },
    edit (item) {
      this.item = item
    },
    details (item) {
      this.item = item
    },
    loadData () {
      const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      RestApi.getData(incentiveGrantServiceBaseUrl, fundDistributionList, params).then(response => {
        if (response.success) {
            this.fundList = this.getRelationalData(response.data.data)
            this.paginationData(response.data)
        } else {
           this.fundList = []
        }
        this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
      })
    },
    getRelationalData (data) {
      const fundSubHeadList = this.$store.state.incentiveGrant.commonObj.fundSubHeadList
      const universityList = this.$store.state.incentiveGrant.commonObj.enlistedUniversityList

      const listData = data.map(item => {
        const fundObj = fundSubHeadList.find(fund => fund.value === item.fund_sub_head_id)
        const versityObj = universityList.find(uv => uv.value === item.university_id)

        const fundSubHeadData = { fund_head_name: fundObj !== undefined ? fundObj.text_en : '', fund_head_name_bn: fundObj !== undefined ? fundObj.text_bn : '' }
        const versityData = { versity_name: versityObj !== undefined ? versityObj.text_en : '', versity_name_bn: versityObj !== undefined ? versityObj.text_bn : '' }

        return Object.assign({}, item, fundSubHeadData, versityData)
      })
      return listData
    },
    getStatus (status) {
      if (status === 1) {
        return this.$t('budget.pending')
      } else if (status === 2) {
        return this.$t('budget.approve')
      } else if (status === 3) {
        return this.$t('externalUserIrrigation.paid')
      }
    }
  }
}
</script>
