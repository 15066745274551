<template>
  <b-container fluid>
    <b-row>
        <b-col lg="12" sm="12">
            <iq-card>
                <template v-slot:body>
                    <b-overlay :show="loading">
                        <b-table-simple bordered>
                            <b-tr>
                                <b-th>{{ $t('globalTrans.application_id') }}</b-th>
                                <b-td>{{ fund.application_id }}</b-td>
                                <b-th>{{ $t('globalTrans.name') }}</b-th>
                                <b-td>{{ ($i18n.locale=='bn') ?  fund.name_bn : fund.name }}</b-td>
                                <b-th>{{ $t('admission_form.candidate_org') }}</b-th>
                                <b-td>{{ getOrganizationName(fund.can_org_type, fund.can_org_id) }}</b-td>
                            </b-tr>
                            <b-tr>
                                <b-th>{{ $t('admission_form.field_study') }}</b-th>
                                <b-td>{{ ($i18n.locale=='bn') ? fund.field_of_study_bn : fund.field_of_study }}</b-td>
                                <b-th>{{ $t('externalUserIrrigation.father_name') }}</b-th>
                                <b-td>{{ ($i18n.locale=='bn') ? fund.father_name_bn : fund.father_name }}</b-td>
                                <b-th>{{ $t('externalUserIrrigation.mother_name') }}</b-th>
                                <b-td>{{ ($i18n.locale=='bn') ? fund.mother_name_bn : fund.mother_name }}</b-td>
                            </b-tr>
                            <b-tr>
                                <b-th>{{ $t('admission_form.nid_no') }}</b-th>
                                <b-td>{{ fund.nid }}</b-td>
                                <b-th>{{ $t('admission_form.mobile_no') }}</b-th>
                                <b-td>{{ ($i18n.locale=='bn') ? fund.mobile_no : fund.mobile_no }}</b-td>
                                <b-th>{{ $t('admission_form.email') }}</b-th>
                                <b-td>{{ ($i18n.locale=='bn') ? fund.email : fund.email }}</b-td>
                            </b-tr>
                            <b-tr>
                                <b-th>{{ $t('admission_form.fund_head') }}</b-th>
                                <b-td>{{ ($i18n.locale=='bn') ? fund.fund_head_name_bn : fund.fund_head_name }}</b-td>
                                <b-th>{{ $t('globalTrans.amount') }}</b-th>
                                <b-td>{{ fund.requisition_amount }}</b-td>
                                <b-th>{{ $t('globalTrans.status') }}</b-th>
                                <b-td>{{ getStatus(fund.status) }}</b-td>
                            </b-tr>
                            <b-tr>
                                <b-th>{{ $t('admission_form.expense_breakdown') }}</b-th>
                                <b-td>
                                    <a target="_blank" v-if="fund.expense_breakdown !== null" :href="baseUrl + '/download-attachment?file=uploads/expense-breakdown/original/' + fund.expense_breakdown" title="Expense Breakdown" class="mr-3"><i class="ri-download-cloud-fill"></i></a>
                                </b-td>
                                <b-th>{{ $t('admission_form.supervisor_recommendation') }}</b-th>
                                <b-td>
                                    <a target="_blank" v-if="fund.supervisor_recommendation !== null" :href="baseUrl + '/download-attachment?file=uploads/supervisor-recommendation/original/' + fund.supervisor_recommendation" title="Supervisor Recommendation" class="mr-3"><i class="ri-download-cloud-fill"></i></a>
                                </b-td>
                            </b-tr>
                        </b-table-simple>
                    </b-overlay>
                </template>
            </iq-card>
        </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import { incentiveGrantServiceBaseUrl } from '@/config/api_config'

export default {
    props: ['item'],
    created () {
        this.fund = this.item
    },
    data () {
        return {
            baseUrl: incentiveGrantServiceBaseUrl,
            loading: true,
            fund: {}
        }
    },
    mounted () {
        core.index()
    },
    methods: {
        getOrganizationName (orgType, orgId) {
            this.loading = false
            if (orgType === 2) {
                const org = this.$store.state.incentiveGrant.commonObj.narseInstituteList.find(item => item.value === orgId)
                return org !== undefined ? (this.$i18n.locale === 'bn' ? org.text_bn : org.text_en) : ''
            } else {
                const org = this.$store.state.orgList.find(item => item.value === orgId)
                return org !== undefined ? (this.$i18n.locale === 'bn' ? org.text_bn : org.text_en) : ''
            }
        },
        getStatus (status) {
            if (status === 1) {
                return this.$t('budget.pending')
            } else if (status === 2) {
                return this.$t('budget.approve')
            } else if (status === 3) {
                return this.$t('externalUserIrrigation.paid')
            }
        }
    }
}
</script>
