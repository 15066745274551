<template>
  <b-container fluid>
    <b-overlay :show="loading">
      <b-row>
        <b-col lg="12" sm="12">
          <iq-card>
            <template v-slot:body>
              <b-row>
                <b-col lg="12" sm="12">
                  <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                    <b-form @submit.prevent="handleSubmit(saveData)" @reset.prevent="reset">
                      <b-row>
                        <b-col lg="4" md="4" sm="12" xs="12">
                            <ValidationProvider name="Applicant Id">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="12"
                                    label-for="applicant_id"
                                    slot-scope="{ valid, errors }"
                                    >
                                    <template v-slot:label>
                                    {{ $t('grantDistribution.applicant_id') }}
                                    </template>
                                    <b-form-input
                                    readonly
                                    :value="fundListShow.application_id"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    >
                                    </b-form-input>
                                    <div class="invalid-feedback">
                                    {{ errors[0] }}
                                    </div>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col lg="4" md="4" sm="12" xs="12">
                          <ValidationProvider name="Applicant Name">
                            <b-form-group
                              class="row"
                              label-cols-sm="12"
                              label-for="name"
                              slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                              {{$t('grantDistribution.applicant_name')}}
                          </template>
                            <b-form-input
                                readonly
                                :value="localeCheck === 'bn' ? fundListShow.name_bn : fundListShow.name"
                                :state="errors[0] ? false : (valid ? true : null)"
                                ></b-form-input>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col lg="4" md="4" sm="12" xs="12">
                          <ValidationProvider name="Organization">
                            <b-form-group
                              class="row"
                              label-cols-sm="12"
                              label-for="can_org_id"
                              slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                              {{$t('grantDistribution.organization')}}
                          </template>
                            <b-form-input
                                readonly
                                id="can_org_id"
                                :value="getOrganizationName(fundListShow.can_org_type, fundListShow.can_org_id)"
                                :state="errors[0] ? false : (valid ? true : null)"
                                ></b-form-input>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="4" md="4" sm="12" xs="12">
                          <ValidationProvider name="univerisity name">
                            <b-form-group
                              class="row"
                              label-cols-sm="12"
                              label-for="can_org_id"
                              slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                              {{$t('grantDistribution.university_name')}}
                          </template>
                            <b-form-input
                                readonly
                                id="can_org_id"
                                :value="localeCheck === 'bn' ? fundListShow.versity_name_bn : fundListShow.versity_name"
                                :state="errors[0] ? false : (valid ? true : null)"
                                ></b-form-input>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="4" md="4" sm="12" xs="12">
                          <ValidationProvider name="course name">
                            <b-form-group
                              class="row"
                              label-cols-sm="12"
                              label-for="can_org_id"
                              slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                              {{$t('grantDistribution.course_name')}}
                          </template>
                            <b-form-input
                                readonly
                                id="can_org_id"
                                :value="localeCheck === 'bn' ? fundListShow.field_of_study_bn : fundListShow.field_of_study"
                                :state="errors[0] ? false : (valid ? true : null)"
                                ></b-form-input>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col lg="4" md="4" sm="12" xs="12">
                          <ValidationProvider name="Fund Sub Head" vid="fund_sub_head_id">
                            <b-form-group
                              class="row"
                              label-cols-sm="12"
                              label-for="fund_sub_head_id"
                              slot-scope="{ valid, errors }"
                              >
                              <template v-slot:label>
                              {{ $t('grantDistribution.fund_sub_head') }}
                              </template>
                              <b-form-select
                                plain
                                v-model="fundListShow.fund_sub_head_id"
                                :options="subHeadList"
                                id="fund_sub_head_id"
                                :state="errors[0] ? false : (valid ? true : null)"
                                :disabled="true"
                                >
                                <template v-slot:first>
                                  <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                              </b-form-select>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                      </b-row>
                      <div class="mb-3">
                        <template v-if="addmissionshow">
                          <div style="font-size:12px; background-color: #66CC99; padding:6px">
                              <h5 class="text-white text-center"> {{ $t('grantDistribution.admission') }}</h5>
                          </div>
                        </template>
                        <template v-if="stipendshow">
                          <div style="font-size:12px; background-color: #66CC99; padding:6px">
                              <h5 class="text-white text-center"> {{ $t('grantDistribution.stipend') }}</h5>
                          </div>
                        </template>
                        <template v-if="researchGrantShow">
                          <div style="font-size:12px; background-color: #66CC99; padding:6px">
                            <h5 class="text-white text-center"> {{ $t('grantDistribution.research_grant') }}</h5>
                          </div>
                        </template>
                        <template v-if="othersshow">
                          <div style="font-size:12px; background-color: #66CC99; padding:6px">
                            <h5 class="text-white text-center"> {{ $t('grantDistribution.others') }}</h5>
                          </div>
                        </template>
                      </div>
                      <div class="row">
                          <b-col lg="6" md="6" sm="12" xs="12">
                            <ValidationProvider name="Total Amount" vid="total_amount">
                              <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="total_amount"
                                slot-scope="{ valid, errors }"
                              >
                              <template v-slot:label>
                                {{$t('grantDistribution.total_amount')}}
                            </template>
                              <b-form-input
                                readonly
                                  id="total_amount"
                                  v-model="form.total_amount"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  ></b-form-input>
                                <div class="invalid-feedback">
                                  {{ errors[0] }}
                                </div>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                          <b-col lg="6" md="6" sm="12" xs="12">
                            <ValidationProvider name="Available Amount" vid="available_amount">
                              <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="available_amount"
                                slot-scope="{ valid, errors }"
                              >
                              <template v-slot:label>
                                {{$t('budget.remainingAmount')}}
                            </template>
                              <b-form-input
                                  readonly
                                  id="available_amount"
                                  v-model="form.available_amount"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  ></b-form-input>
                                <div class="invalid-feedback">
                                  {{ errors[0] }}
                                </div>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                          <b-col lg="6" md="6" sm="12" xs="12">
                            <ValidationProvider name="Payment Amount" vid="pay_amount" rules="required|min_value:1|max_value:@available_amount">
                              <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="pay_amount"
                                slot-scope="{ valid, errors }"
                              >
                              <template v-slot:label>
                                {{ $t('grantDistribution.pay_amount') }} <span class="text-danger">*</span>
                            </template>
                              <b-form-input
                                  oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                  type="text"
                                  id="pay_amount"
                                  v-model="form.pay_amount"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  ></b-form-input>
                                <div class="invalid-feedback">
                                  {{ errors[0] }}
                                </div>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                        <b-col lg="6" md="6" sm="12" xs="12">
                          <ValidationProvider name="Payment Date" vid="payment_date" rules="required">
                            <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="payment_date"
                              slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                              {{$t('grantDistribution.payment_date')}} <span class="text-danger">*</span>
                          </template>
                            <b-form-input
                                id="payment_date"
                                v-model="form.payment_date"
                                :state="errors[0] ? false : (valid ? true : null)"
                                ></b-form-input>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" md="6" sm="12" xs="12">
                          <ValidationProvider name="Remarks" vid="remarks">
                            <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="remark"
                              slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                              {{$t('grantDistribution.remarks_en')}}
                          </template>
                            <b-form-textarea
                                id="remark"
                                row="3"
                                col="2"
                                v-model="form.remark"
                                :state="errors[0] ? false : (valid ? true : null)"
                                ></b-form-textarea>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" md="6" sm="12" xs="12">
                            <ValidationProvider name="Remarks (Bn)" vid="remarks_bn">
                              <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="remark_bn"
                                slot-scope="{ valid, errors }"
                              >
                              <template v-slot:label>
                                {{$t('grantDistribution.remarks_bn')}}
                            </template>
                              <b-form-textarea
                                  id="remark_bn"
                                  row="3"
                                  col="2"
                                  v-model="form.remark_bn"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                ></b-form-textarea>
                                <div class="invalid-feedback">
                                  {{ errors[0] }}
                                </div>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                      </div>
                      <div class="row">
                        <div class="col-sm-3"></div>
                        <div class="col text-right">
                          <b-button type="submit" variant="primary">{{ saveBtnName}}</b-button>
                          &nbsp;
                          <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-4')">{{ $t('globalTrans.cancel') }}</b-button>
                        </div>
                      </div>
                    </b-form>
                  </ValidationObserver>
                </b-col>
              </b-row>
            </template>
          </iq-card>
        </b-col>
      </b-row>
    </b-overlay>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { incentiveGrantServiceBaseUrl } from '@/config/api_config'
import { fundDistributionStore, getAvailableFund } from '../../api/routes'
import flatpickr from 'flatpickr'

export default {
  props: ['item'],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  created () {
    this.fundListShow = this.item
    this.getTypeData(this.fundListShow.fund_sub_head_id)
    this.getAvailableAmount(this.fundListShow.fund_sub_head_id)
  },
  mounted () {
    core.index()
    flatpickr('#payment_date', {})
  },
  data () {
    return {
      loading: false,
      addmissionshow: false,
      stipendshow: false,
      researchGrantShow: false,
      othersshow: false,
      totalPost: 0,
      saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      form: {
        fund_sub_head_id: 0,
        total_amount: '',
        available_amount: '',
        payment_date: '',
        pay_amount: '',
        due_amount: 0,
        type: '',
        remarks: '',
        remarks_bn: ''
      },
     fundListShow: {}
    }
  },
  computed: {
    subHeadList: function () {
     const headlist = this.$store.state.incentiveGrant.commonObj.fundSubHeadList.filter(item => item.status === 1)
      return headlist.map(item => {
       if (this.$i18n.locale === 'bn') {
          return { value: item.value, text: item.text_bn }
          } else {
          return { value: item.value, text: item.text_en }
        }
      })
    },
    localeCheck: function () {
      return this.$i18n.locale
    }
  },
  watch: {
    'form.pay_amount': function (newVal, oldVal) {
      if (oldVal !== newVal) {
        this.checkAmount(newVal)
      }
    }
  },
  methods: {
    getTypeData (typeId) {
      if (typeId === 1) {
        this.addmissionshow = true
        this.stipendshow = false
        this.researchGrantShow = false
        this.othersshow = false
      } else if (typeId === 2) {
        this.addmissionshow = false
        this.stipendshow = true
        this.researchGrantShow = false
        this.othersshow = false
      } else if (typeId === 3) {
        this.addmissionshow = false
        this.stipendshow = false
        this.researchGrantShow = true
        this.othersshow = false
      } else if (typeId === 4) {
        this.addmissionshow = false
        this.stipendshow = false
        this.researchGrantShow = false
        this.othersshow = true
      }
    },
    async saveData () {
      this.loading = true
      const formData = Object.assign(this.form, { type: this.fundListShow.fund_sub_head_id, fund_sub_head_id: this.fundListShow.fund_sub_head_id, app_general_info_id: this.fundListShow.app_gen_info_id })
      const result = await RestApi.postData(incentiveGrantServiceBaseUrl, `${fundDistributionStore}`, formData)
      this.loading = false
      if (result.success) {
        // push notification
        // const notification = result.notification
        // this.$socket.emit('send-notification', notification)

          this.$toast.success({
            title: this.$t('globalTrans.success'),
            message: this.$t('globalTrans.save_msg'),
            color: '#D6E09B'
          })

          this.$bvModal.hide('modal-4')
      } else {
          this.$refs.form.setErrors(result.errors)
      }
    },
    getOrganizationName (orgType, orgId) {
      if (orgType === 2) {
          const org = this.$store.state.incentiveGrant.commonObj.narseInstituteList.find(item => item.value === orgId)
          return org !== undefined ? (this.$i18n.locale === 'bn' ? org.text_bn : org.text_en) : ''
      } else {
          const org = this.$store.state.orgList.find(item => item.value === orgId)
          return org !== undefined ? (this.$i18n.locale === 'bn' ? org.text_bn : org.text_en) : ''
      }
    },
    async getAvailableAmount (fundSubHead) {
      this.loading = true
      const fund = this.$store.state.incentiveGrant.commonObj.fundSubHeadList.find(fund => fund.value === fundSubHead)
      const params = Object.assign({ fund_sub_head_id: fundSubHead, app_gen_info_id: this.fundListShow.app_gen_info_id })
      await RestApi.getData(incentiveGrantServiceBaseUrl, getAvailableFund, params)
      .then(response => {
        this.form.available_amount = this.getFundAmount(fund.amount, fundSubHead, response)
        this.loading = false
      })
    },
    getFundAmount (fundAmount, fundSubHead, response) {
      this.form.pay_amount = this.fundListShow.requisition_amount
      if (fundSubHead === 2 || fundSubHead === 3) {
        // this.form.total_amount = (parseFloat(fundAmount) * parseFloat(response.deputation))
        // if (response.success) {
        //   return (parseFloat(fundAmount) * parseFloat(response.deputation) - response.paid)
        // } else {
        //   return (parseFloat(fundAmount) * parseFloat(response.deputation))
        // }
        this.form.total_amount = fundAmount
        if (response.success) {
          return parseFloat(fundAmount - response.paid_amount)
        } else {
          return (parseFloat(fundAmount) * parseFloat(response.deputation))
        }
      } else {
        this.form.total_amount = 0
        return this.fundListShow.requisition_amount
      }
    },
    checkAmount (payAmount) {
      if (this.form.fund_sub_head_id === 2 || this.form.fund_sub_head_id === 3) {
        if (payAmount > this.form.available_amount) {
          this.$toast.error({
            title: this.$t('globalTrans.error'),
            message: this.$t('admission_form.requision_msg')
          })
          this.form.pay_amount = 0
        }
      }
    }
  }
}
</script>
